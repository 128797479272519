.address{
    grid-area: address;
    display: grid;
    font-size: 13px;
    font-weight: 200;
    max-width: 700px;
    grid-template-columns: 1fr;
}

.address-text{
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}