.launch{
    overflow: hidden;
    grid-area: launch;
    display: grid;
    font-size: auto 20px;
    font-weight: 1000;
    max-width: 700px;
    grid-template-columns: 1fr;
}

.launch-text{
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}