.background {
  display: grid;
  grid-gap: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "logo"
    "description"
    "launch"
    "subscribe";
  justify-items: center;
  align-items: center;
  color: var(--black);
  height: auto
}

.footer {
  display: grid;
  grid-gap: 0.5px;
  grid-template-columns: 1fr;
  grid-template-rows: 15px 15px 15px;
  grid-template-areas:
    "copyright"
    "address"
    "email";
  justify-items: center;
  align-items: center;
  color: var(--black);
  /* height: 0.5px */
}